import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  IMasterDepositoryFilter,
  MasterKnowledgeCoursesGetRequest,
  MasterCoursesUsefulCourseIdGetParams,
  MasterCoursesUsefulCourseIdGetRequest,
  UsefulMaterialMasterPaginator,
  KnowledgeCoursesPaginatorResource,
  CourseFolderListResource,
  MasterKnowledgeCoursesCourseIdGetRequest,
  MasterKnowledgeCourseIdTreeCourseFolderIdGetRequest,
  KnowledgeTreeResource,
  MasterKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdGetRequest,
  IUsefulMaterialsFilter,
  MasterCoursesUsefulCourseIdSelectGetParams,
  MasterCoursesUsefulCourseIdSelectGetRequest,
  MasterKnowledgeCourseIdGetRequest,
  MasterCoursesUsefulCourseIdViewMaterialIdPatchRequest,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Банк заданий мастера
 * - получение списка курсов
 * - просмотр документов прикрепленных за выбранным курсом
 */

@Module({
  dynamic: true,
  name: 'MasterDepository',
  namespaced: true,
  store,
})
class MasterDepository extends VuexModule {
  // ---------------------------- Courses ---------------------------- >>
  // Filter
  coursesFilter: IMasterDepositoryFilter = { ...GET_DEFAULT_TABLE_FILTER(), itemsPerPage: 10 }

  @Mutation
  setCoursesFilter (payload: IMasterDepositoryFilter) {
    this.coursesFilter = { ...payload }
  }

  // Entities
  courses: KnowledgeCoursesPaginatorResource = GET_DEFAULT_PAGINATOR()

  @Mutation
  setCourses (payload: KnowledgeCoursesPaginatorResource) {
    this.courses = payload
  }

  @Action({ rawError: true })
  async fetchCourses () {
    const { data } = await MasterKnowledgeCoursesGetRequest(tableOptionsToParams(this.coursesFilter))
    this.setCourses(data)
    return data
  }

  @Action({ rawError: true })
  async fetchCourse(payload: number) {
    const { data } = await MasterKnowledgeCoursesCourseIdGetRequest(payload)
    return data
  }

  // ---------------------------- Course ---------------------------- >>
  currentCourseID: number | null = null

  @Mutation
  setCurrentCourseID(id: number) {
    // Очищаем фильтры полезных материалов, если текущий ид курса не совпадает с пришедшим
    if (id && this.currentCourseID !== id) {
      this.materialsFilter = {
        ...GET_DEFAULT_TABLE_FILTER(),
        perPage: 5,
      }
    }

    this.currentCourseID = id
  }

  // ---------------------------- Materials ---------------------------- >>
  // Entities
  materials: UsefulMaterialMasterPaginator = GET_DEFAULT_PAGINATOR()
  materialsFilter: IUsefulMaterialsFilter = {
    ...GET_DEFAULT_TABLE_FILTER(),
    perPage: 5,
  }

  @Mutation
  setMaterials(payload: UsefulMaterialMasterPaginator) {
    this.materials = payload
  }

  @Mutation
  setMaterialsFilter(payload: IUsefulMaterialsFilter) {
    this.materialsFilter = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchMaterials(payload: { courseId: number, params?: MasterCoursesUsefulCourseIdGetParams }) {
    const { data } = await MasterCoursesUsefulCourseIdGetRequest(payload.courseId, payload.params)
    this.setMaterials(data)
    return data
  }

  // Получения списка тайтлов полезных материалов для поиска
  @Action({ rawError: true })
  async fetchTitleMaterials(payload: { courseID: number, params?: MasterCoursesUsefulCourseIdSelectGetParams }) {
    const { data } = await MasterCoursesUsefulCourseIdSelectGetRequest(payload.courseID, payload.params, { loading: false })
    return data
  }
  // -- Получения списка тайтлов полезных материалов для поиска --

  // Пометить полезный материал как просмотренный
  @Action({ rawError: true })
  async checkedMaterials(payload: { courseID: number, materialID: number }) {
    const { data } = await MasterCoursesUsefulCourseIdViewMaterialIdPatchRequest(payload.courseID, payload.materialID)
    return data
  }
  // -- Пометить полезный материал как просмотренный --

  // Обновить список материалов после изучения
  @Mutation
  updateListMaterials(materialID: number) {
    this.materials.data = this.materials.data.map(item => {
      if (item.id === materialID) {
        return {
          ...item,
          isViewed: true,
        }
      }
      return item
    })
  }
  // -- Обновить список материалов после изучения --

  // ---------------------------- Training Files ---------------------------- >>
  // Entities
  trainingFilesMonth: { courseID: number | null, folders: CourseFolderListResource[] } = {
    courseID: null,
    folders: [],
  }
  trainingFilesTree: KnowledgeTreeResource[] = []

  // Получить месяцы учебный файлов курса
  @Mutation
  setTrainingFilesMonth(payload: { courseID: number | null, folders: CourseFolderListResource[] }) {
    this.trainingFilesMonth = {
      courseID: payload.courseID,
      folders: payload.folders,
    }
  }

  @Action({ rawError: true })
  async fetchTrainingFilesMonth(payload: { courseID: number }) {
    const { data } = await MasterKnowledgeCourseIdGetRequest(payload.courseID)
    this.setTrainingFilesMonth({
      courseID: payload.courseID,
      folders: data,
    })
    return data
  }
  // -- Получить месяцы учебный файлов курса --

  // Получить дерево папок месяца
  @Mutation
  private setTrainingFilesTree(payload: KnowledgeTreeResource[]) {
    this.trainingFilesTree = payload
  }

  @Action({ rawError: true })
  async fetchTrainingFilesTree(payload: { courseID: number, courseFolderID: number }) {
    const { data } = await MasterKnowledgeCourseIdTreeCourseFolderIdGetRequest(payload.courseID, payload.courseFolderID)
    this.setTrainingFilesTree(data)
    return data
  }
  // -- Получить дерево папок месяца --

  // Получить содержимое папки
  @Action({ rawError: true })
  async fetchTrainingFilesFolder(payload: { courseID: number, courseFolderID: number, mediaFolderID: number }) {
    const { data } = await MasterKnowledgeCourseIdTreeCourseFolderIdFolderMediaFolderIdGetRequest(payload.courseID, payload.courseFolderID, payload.mediaFolderID)
    return data
  }
  // -- Получить содержимое папки --
}

const MasterDepositoryModule = getModule(MasterDepository)

export default MasterDepositoryModule
