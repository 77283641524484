





















import { Component, Prop, Vue } from 'vue-property-decorator'

interface IList {
  icon?: string,
  iconColor?: string,
  name: string,
  value: string,
}

@Component
export default class SortMenu extends Vue {
  @Prop({ default: () => ([]) })
  private list!: IList[]

  @Prop({ default: 'desc' })
  private sortValue!: 'asc' | 'desc'

  private get icon() {
    return this.sortValue === 'asc' ? '$sortUp' : '$sortDown'
  }

  private handleOptionClick (item: IList) {
    this.$emit('sort-action', item.value)
  }
}
