
























































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// mixins
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// components
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import MasterMaterialCard from '@/components/cards/MasterMaterialCard.vue'
import NoDataFound from '@/components/NoDataFound.vue'
import PaginationWrapper from '@/components/_uikit/PaginationWrapper.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import SortMenu from '@/components/views/depository/SortMenu.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// store
import { FullUsefulMaterial, IUsefulMaterialsFilter, NameValueResource } from '@/store/types'
import AuthModule from '@/store/modules/auth'
import MasterDepositoryModule from '@/store/modules/master/depository'
import DictionaryModule from '@/store/modules/dictionary'
import { convertDateToMSK } from '@/utils/functions'

@Component({
  components: {
    DateInput,
    MasterMaterialCard,
    NoDataFound,
    PaginationWrapper,
    Select,
    SortMenu,
    TableFooter,
    TextInput,
  },
})
export default class MasterDepositoryItemMaterials extends Mixins(DetectSafariMixin, NotifyMixin, PermissionsMixin) {
  private courseID = this.$route.params.courseID

  private get filter() {
    return MasterDepositoryModule.materialsFilter
  }

  private set filter(filter: IUsefulMaterialsFilter) {
    MasterDepositoryModule.setMaterialsFilter(filter)
    this.fetchMaterials()
  }

  private filterDate: string[] = this.filter.startDate && this.filter.endDate ? [this.filter.startDate, this.filter.endDate] : []

  private get materialTypes(): NameValueResource[] {
    return DictionaryModule.usefulMaterialsTypes
  }

  private width = window.innerWidth

  private get pagination() {
    return MasterDepositoryModule.materials.meta
  }

  private get maxHeight() {
    if (this.width > 1024) {
      return 220
    }
    if (this.width <= 1024 && this.width > 449) {
      return 180
    }
    return 140
  }

  private isLoading = false
  private list = [
    { icon: '$sortDown', iconColor: 'teal', name: 'От нового к старому', value: 'desc' },
    { icon: '$sortUp', iconColor: 'teal', name: 'От старого к новому', value: 'asc' },
  ]
  private search = ''
  private titleMaterialsSelect: string[] = []

  private mounted() {
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.filterDate = [this.$route.query.startDate as string, this.$route.query.endDate as string]
      this.filter.startDate = this.filterDate[0]
      this.filter.endDate = this.filterDate[1]
    }
    this.filter.sortOrder = 'desc'
    window.addEventListener('resize', this.changeWidth)
    this.fetchMaterials()
  }

  private destroyed() {
    window.removeEventListener('resize', this.changeWidth)
  }

  private changeWidth() {
    this.width = window.innerWidth
  }

  private get materials(): FullUsefulMaterial[] {
    return MasterDepositoryModule.materials.data
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private handleFilter(key: string, value: string) {
    this.filter = {
      ...this.filter,
      [key]: value,
      page: 1,
    }
  }

  private handleFilterDate(date: string[]) {
    if (date.length) {
      this.filter = {
        ...this.filter,
        endDate: convertDateToMSK(`${date[1]} 23:59:59`, this.isLocalTimezone),
        page: 1,
        startDate: convertDateToMSK(`${date[0]} 00:00:00`, this.isLocalTimezone),
      }
    } else {
      delete this.filter.endDate
      delete this.filter.startDate
      this.filter = {
        ...this.filter,
        page: 1,
      }
    }
  }

  private handleSortAction(value: 'asc' | 'desc') {
    this.handleFilter('sortOrder', value)
  }

  @Bind
  @Debounce(300)
  private fetchMaterials() {
    MasterDepositoryModule.fetchMaterials({ courseId: +this.courseID, params: this.filter })
      .catch(this.notifyError)
  }

  private fetchTitleMaterials(query: string | null) {
    if (this.isLoading) return

    this.isLoading = true
    MasterDepositoryModule.fetchTitleMaterials({ courseID: +this.courseID, params: { query: query || undefined } })
      .then(response => {
        this.titleMaterialsSelect = response
      })
      .catch(this.notifyError)
      .finally(() => this.isLoading = false)
  }

  @Watch('filterDate')
  private watchFilterDate() {
    this.handleFilterDate(this.filterDate)
  }

  @Bind
  @Debounce(500)
  @Watch('search')
  private handleSearch(value: string | null, oldValue: string | null) {
    if (value && value !== oldValue) {
      this.fetchTitleMaterials(value)
    } else if (value === null) {
      this.titleMaterialsSelect = []
    }
  }
}
